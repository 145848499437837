import React from "react";
import { Link } from "gatsby";
import Logo from "../../assets/edclerkLogo2.svg";
//import EDname from "../assets/EDname.svg";

console.log(process.env.BASE_DOMAIN);

function Header() {
  function handleSignIn() {
    window.open(`https://app.${window.location.hostname}/sign-in`);
  }

  return (
    <nav className="flex flex-wrap mr-10">
      <div className="flex">
        <div className="absolute flex bg-white w-full mt-5 ">
          <div className="flex opacity-85  -pt-2 -pb-2">
            <Link to="/">
              <Logo className="flex -mx-7 -my-10 py-2" />
            </Link>
          </div>

          <a
            href={handleSignIn}
            className="transition-all flex duration-500 font-medium "
          >
            <div className="flex text-black text-bold pt-5 text-lg ">
              Sign In
            </div>
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Header;