import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header from "../components/Header/Header";

function PrivacyPage() {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Edclerk</title>
        <meta
          name="keywords"
          content="HB4545, restroom passes, hallway monitoring, PBIS, SEL, organize, positive, support, easy, teachers"
        />
        <meta
          name="description"
          content="Helping principals of public schools prevent compliance issues,
          organize compliance documents, and keep track of student behavior
          with cloud based software that notify teachers and mentors in a
          collaborative interface"
        />
        <meta name="icon" href="/favicon.ico" />
      </Helmet>
      <section className="section py-14">
        <div className="container mx-auto">
          <Header />
          <main className="mt-12">
            <h1 className="text-2xl mb-1">Privacy Notice</h1>
            <p className="mb-6">Last updated February 01, 2020</p>
            <p className="mb-3">
              Thank you for choosing to be part of our community at Document
              Daily LLC, doing business as Document Daily (“Document Daily”,
              “we”, “us”, or “our”). We are committed to protecting your
              personal information and your right to privacy. If you have any
              questions or concerns about our notice, or our practices with
              regards to your personal information, please contact us at
              brianspeer@documentdaily.com.
            </p>
            <p className="mb-3">
              When you visit our website documentdaily.com, and use our
              services, you trust us with your personal information. We take
              your privacy very seriously. In this privacy notice, we seek to
              explain to you in the clearest way possible what information we
              collect, how we use it and what rights you have in relation to it.
              We hope you take some time to read through it carefully, as it is
              important. If there are any terms in this privacy notice that you
              do not agree with, please discontinue use of our Sites and our
              services.
            </p>
            <p className="mb-3">
              This privacy notice applies to all information collected through
              our website (such as documentdaily.com), and/or any related
              services, sales, marketing or events (we refer to them
              collectively in this privacy notice as the "Services").
            </p>
            <p className="mb-3">
              Please read this privacy notice carefully as it will help you make
              informed decisions about sharing your personal information with
              us.
            </p>
            <h4 className="font-bold mb-3">
              1. WHAT INFORMATION DO WE COLLECT?
            </h4>
            <p className="mb-1">
              <strong>Information automatically collected</strong>
            </p>
            <p className="mb-3">
              <i>
                In Short: Some information — such as IP address and/or browser
                and device characteristics — is collected automatically when you
                visit our Services.
              </i>
            </p>
            <p className="mb-3">
              We automatically collect certain information when you visit, use
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </p>
            <p className="mb-3">
              Like many businesses, we also collect information through cookies
              and similar technologies.
            </p>
            <p className="mb-1">
              <strong>Information collected from other sources</strong>
            </p>
            <p className="mb-3">
              <i>
                In Short: We may collect limited data from public databases,
                marketing partners, and other outside sources.
              </i>
            </p>
            <p className="mb-3">
              We may obtain information about your from other sources, such as
              public databases, joint marketing partners, as well as from other
              third parties. Examples of the information we receive from other
              sources include: social media profile information; marketing leads
              and search results and links, including paid listings (such as
              sponsored links). We will inform you about the source of
              information and the type of information and the type of
              information we have collected about you within a reasonable period
              after obtaining the personal data, but at the latest within one
              month.
            </p>

            <h4 className="font-bold mb-3">
              2. HOW DO WE USE YOUR INFORMATION?
            </h4>
            <p className="mb-3">
              <i>
                In Short: We process your information for purposes based on
                legitimate business interests, the fulfillment of our contract
                with you, compliance with our legal obligations, and/or your
                consent.
              </i>
            </p>
            <p className="mb-3">
              We use personal information collected via our Services for a
              variety of business purposes described below. We process your
              personal information for these purposes in reliance on our
              legitimate business interests, in order to enter into or perform a
              contract with you, with your consent, and/or for compliance with
              our legal obligations. We indicate the specific processing grounds
              we rely on next to each purpose listed below.
            </p>
            <p className="mb-3">
              We use the information we collect or receive:
            </p>
            <p className="mb-3">
              Request Feedback. We may use your information to request feedback
              and to contact you about your use of our Services.
            </p>
            <p className="mb-3">
              To protect our Services. We may use your information as part of
              our efforts to keep our Services safe and secure (for example, for
              fraud monitoring and prevention).
            </p>
            <p className="mb-3">
              To enable user-to-user communications. We may use your information
              in order to enable user-to-user communications with each user's
              consent.
            </p>
            <p className="mb-3">
              To enforce our terms, conditions and policies for Business
              Purposes, Legal Reasons and Contractual.
            </p>
            <p className="mb-3">
              To respond to legal requests and prevent harm. If we receive a
              subpoena or other legal request, we may need to inspect the data
              we hold to determine how to respond.
            </p>
            <p className="mb-3">
              To manage user accounts. We may use your information for the
              purposes of managing our account and keeping it in working order.
            </p>
            <p className="mb-3">
              To deliver services to the user. We may use your information to
              provide you with the requested service.
            </p>
            <p className="mb-3">
              To respond to user inquiries/offer support to users. We may use
              your information to respond to your inquiries and solve any
              potential issues you might have with the use of our Services.
            </p>
            <p className="mb-3">
              For other Business Purposes. We may use your information for other
              Business Purposes, such as data analysis, identifying usage
              trends, determining the effectiveness of our promotional campaigns
              and to evaluate and improve our Services, products, marketing and
              your experience. We may use and store this information in
              aggregated and anonymized form so that it is not associated with
              individual end users and does not include personal information. We
              will not use identifiable personal information without your
              consent.
            </p>
            <h4 className="font-bold mb-3">
              3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
            </h4>
            <p className="mb-3">
              <i>
                In Short: We only share information with your consent, to comply
                with laws, to provide you with services, to protect your rights,
                or to fulfill business obligations.
              </i>
            </p>
            <p className="mb-3">
              We may process or share data based on the following legal basis:
            </p>
            <ul className="list-disc">
              <li>
                Consent: We may process your data if you have given us specific
                consent to use your personal information in a specific purpose.
              </li>
              <li>
                Legitimate Interests: We may process your data when it is
                reasonably necessary to achieve our legitimate business
                interests.
              </li>
              <li>
                Performance of a Contract: Where we have entered into a contract
                with you, we may process your personal information to fulfill
                the terms of our contract.
              </li>
              <li>
                Legal Obligations: We may disclose your information where we are
                legally required to do so in order to comply with applicable
                law, governmental requests, a judicial proceeding, court order,
                or legal process, such as in response to a court order or a
                subpoena (including in response to public authorities to meet
                national security or law enforcement requirements).
              </li>
              <li>
                Vital Interests: We may disclose your information where we
                believe it is necessary to investigate, prevent, or take action
                regarding potential violations of our policies, suspected fraud,
                situations involving potential threats to the safety of any
                person and illegal activities, or as evidence in litigation in
                which we are involved.
              </li>
            </ul>
            <p className="mt-5 mb-3">
              More specifically, we may need to process your data or share your
              personal information in the following situations:
            </p>
            <ul className="list-disc">
              <li>
                Vendors, Consultants and Other Third-Party Service Providers. We
                may share your data with third party vendors, service providers,
                contractors or agents who perform services for us or on our
                behalf and require access to such information to do that work.
                Examples include: payment processing, data analysis, email
                delivery, hosting services, customer service and marketing
                efforts. We may allow selected third parties to use tracking
                technology on the Services, which will enable them to collect
                data about how you interact with the Services over time. This
                information may be used to, among other things, analyze and
                track data, determine the popularity of certain content and
                better understand online activity. Unless described in this
                Policy, we do not share, sell, rent or trade any of your
                information with third parties for their promotional purposes.
              </li>
              <li>
                Business Transfers. We may share or transfer your information in
                connection with, or during negotiations of, any merger, sale of
                company assets, financing, or acquisition of all or a portion of
                our business to another company.
              </li>
              <li>
                Third-Party Advertisers. We may use third-party advertising
                companies to serve ads when you visit the Services. These
                companies may use information about your visits to our
                Website(s) and other websites that are contained in web cookies
                and other tracking technologies in order to provide
                advertisements about goods and services of interest to you.
              </li>
              <li>
                Other Users. When you share personal information (for example,
                by posting comments, contributions or other content to the
                Services) or otherwise interact with public areas of the
                Services, such personal information may be viewed by all users
                and may be publicly distributed outside the Services in
                perpetuity. Similarly, other users will be able to view
                descriptions of your activity, communicate with you within our
                Services, and view your profile.
              </li>
            </ul>
            <h4 className="font-bold mt-3 mb-3">
              4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h4>
            <p className="mb-3">
              <i>
                In Short: We may use cookies and other tracking technologies to
                collect and store your information.
              </i>
            </p>
            <p className="mb-3">
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Policy.
            </p>
            <h4 className="font-bold mb-3">
              5. HOW LONG DO WE KEEP YOUR INFORMATION?
            </h4>
            <p className="mb-3">
              <i>
                In Short: We keep your information for as long as necessary to
                fulfill the purposes outlined in this privacy notice unless
                otherwise required by law.
              </i>
            </p>
            <p className="mb-3">
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              policy will require us keeping your personal information for
              longer than __________.
            </p>
            <p className="mb-3">
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize it, or,
              if this is not possible (for example, because your personal
              information has been stored in backup archives), then we will
              securely store your personal information and isolate it from any
              further processing until deletion is possible.
            </p>
            <h4 className="font-bold mb-3">DELETE AND EDIT INFORMATION</h4>
            <p className="mb-3">
              We reserve the right, but undertake no duty, in our sole
              discretion, with or without notice, to review, edit, move, add,
              delete, or otherwise change any Content available on or through,
              or downloadable from the Document Daily website, including without
              limitation any Content in your account, or any of your messages,
              posts, or threads.
            </p>
            <h4 className="font-bold mb-3">
              6. HOW DO WE KEEP YOUR INFORMATION SAFE?
            </h4>
            <p className="mb-3">
              <i>
                In Short: We aim to protect your personal information through a
                system of organizational and technical security measures.
              </i>
            </p>
            <p className="mb-3">
              We have implemented appropriate technical and organizational
              security measures designed to protect the security of any personal
              information we process. However, please also remember that we
              cannot guarantee that the internet itself is 100% secure. Although
              we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the services within a
              secure environment.
            </p>
            <h4 className="font-bold mb-3">
              7. DO WE COLLECT INFORMATION FROM MINORS?
            </h4>
            <p className="mb-3">
              <i>
                In Short: We do not knowingly collect data from or market to
                children under 18 years of age.
              </i>
            </p>
            <p className="mb-3">
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we have collected from children
              under age 18, please contact us at brianspeer@documentdaily.com.
            </p>

            <h4 className="font-bold mb-3">8. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
            <p className="mb-3">
              <i>
                In Short: You may review, change, or terminate your account at
                any time.
              </i>
            </p>
            <p className="mb-3">
              If you are resident in the European Economic Area and you believe
              we are unlawfully processing your personal information, you also
              have the right to complain to your local data protection
              supervisory authority. You can find their contact details{" "}
              <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.">
                here
              </a>
            </p>
            <p className="mb-3">
              Cookies and similar technologies: Most Web browsers are set to
              accept cookies by default. If you prefer, you can usually choose
              to set your browser to remove cookies and to reject cookies. If
              you choose to remove cookies or reject cookies, this could affect
              certain features or services of our Services. To opt-out of
              interest-based advertising by advertisers on our Services visit
              <a href="http://www.aboutads.info/choices/">
                http://www.aboutads.info/choices/.
              </a>
            </p>
            <h4 className="font-bold mb-3">
              9. CONTROLS FOR DO-NOT-TRACK FEATURES
            </h4>
            <p className="mb-3">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track (“DNT”) feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. No
              uniform technology standard for recognizing and implementing DNT
              signals has been finalized. As such, we do not currently respond
              to DNT browser signals or any other mechanism that automatically
              communicates your choice not to be tracked online. If a standard
              for online tracking is adopted that we must follow in the future,
              we will inform you about that practice in a revised version of
              this privacy notice.
            </p>
            <h4 className="font-bold mb-3">
              DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h4>
            <p className="mb-3">
              <i>
                In Short: Yes, if you are a resident of California, you are
                granted specific rights regarding access to your personal
                information.
              </i>
            </p>
            <p className="mb-3">
              California Civil Code Section 1798.83, also known as the “Shine
              The Light” law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p className="mb-3">
              If you are under 18 years of age, reside in California, and have a
              registered account with the Services, you have the right to
              request removal of unwanted data that you publicly post on the
              Services. To request removal of such data, please contact us using
              the contact information provided below, and include the email
              address associated with your account and a statement that you
              reside in California. We will make sure the data is not publicly
              displayed on the Services, but please be aware that the data may
              not be completely or comprehensively removed from our systems.
            </p>
            <h4 className="font-bold mb-3">
              11. DO WE MAKE UPDATES TO THIS POLICY?
            </h4>
            <p className="mb-3">
              <i>
                In Short: Yes, we will update this policy as necessary to stay
                compliant with relevant laws.
              </i>
            </p>
            <p className="mb-3">
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated “Revised” date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
            <h4 className="font-bold mb-3">
              12. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
            </h4>
            <p className="mb-3">
              If you have questions or comments about this policy, you may email
              us at brianspeer@documentdaily.com or by post to:
            </p>
            <p className="mb-3">
              Document Daily LLC <br />
              11625 FM 450 N <br />
              HARLETON, TX 75651 <br />
              United States
            </p>
            <h4 className="font-bold mb-3">
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h4>
            <p className="mb-3">
              Based on the laws of some countries, you may have the right to
              request access to the personal information we collect from you,
              change that information, or delete it in some circumstances. To
              request to review, update, or delete your personal information,
              please submit a request form by clicking here. We will respond to
              your request within 30 days.
            </p>
          </main>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default PrivacyPage;